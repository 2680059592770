import React from "react"
import { Content, Layout, SEO } from "../components"

export default () => {
  return (
    <Layout>
      <SEO title="404 not found" />
      <Content>
        <h1>404: NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Content>
    </Layout>
  )
}
